import React, { useState } from 'react';
import { CircularProgress, TextField, Button, Snackbar, Alert } from '@mui/material';

const ContactMe = () => {
    const [guestName, setGuestName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [messageTitle, setMessageTitle] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [errors, setErrors] = useState({});
    const [statusMessage, setStatusMessage] = useState('');
    const [snackbar, setSnackbar] = useState({ open: false, message: '', type: '' });
    const [loading, setLoading] = useState(false);

    const validate = () => {
        const errors = {};
        if (!guestName.trim()) errors.guestName = 'Guest name is required';
        if (!email.trim()) {
            errors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email address is invalid';
        }
        if (!messageTitle.trim()) errors.messageTitle = 'Message title is required';
        if (!messageBody.trim()) errors.messageBody = 'Message body is required';
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            setSnackbar({ open: true, message: 'Please fix the errors in the form.', type: 'error' });
            return;
        }
        setErrors({});
        setLoading(true);

        const data = {
            guestName,
            email,
            phoneNumber,
            messageTitle,
            messageBody,
        };

        try {
            const response = await fetch('https://api.madhavthapa.click/v1/contact-me', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': "4pUvgF3aOD7oadRG8YIUN5MZnJU7yVE94QGflHZA"
                },
                body: JSON.stringify({
                    subject: data.messageTitle,
                    message: `${data.messageBody}\n${data.guestName}\n${data.phoneNumber}\n${data.email}`,
                }),
            });

            if (response.ok) {
                setStatusMessage('Success: Your message has been sent!');
                setSnackbar({ open: true, message: 'Your message has been sent successfully!', type: 'success' });
                setGuestName('');
                setEmail('');
                setPhoneNumber('');
                setMessageTitle('');
                setMessageBody('');
            } else {
                setStatusMessage('Failure: There was a problem sending your message.');
                setSnackbar({ open: true, message: 'There was a problem sending your message.', type: 'error' });
            }
        } catch (error) {
            setStatusMessage('Failure: Network error.');
            setSnackbar({ open: true, message: 'Network error. Please try again later.', type: 'error' });
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = () => {
        setSnackbar({ ...snackbar, open: false });
    };

    const styles = {
        container: {
            backgroundColor: '#f9f9f9',
            marginTop: '20px',
            marginBottom: '20px',
            padding: '20px',
            borderRadius: '8px',
            maxWidth: '600px',
            margin: '0 auto',
            boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        },
        formGroup: {
            marginBottom: '15px',
        },
        button: {
            padding: '10px 15px',
            borderRadius: '4px',
            border: 'none',
            backgroundColor: '#007BFF',
            color: '#fff',
            fontSize: '16px',
            cursor: 'pointer',
        },
    };

    return (
        <div style={styles.container}>
            <h2>Contact Me</h2>
            <form onSubmit={handleSubmit}>
                <div style={styles.formGroup}>
                    <TextField
                        label="Guest Name"
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        error={!!errors.guestName}
                        helperText={errors.guestName}
                        fullWidth
                        InputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                </div>
                <div style={styles.formGroup}>
                    <TextField
                        label="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        error={!!errors.email}
                        helperText={errors.email}
                        fullWidth
                        InputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                </div>
                <div style={styles.formGroup}>
                    <TextField
                        label="Phone Number (optional)"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        fullWidth
                        InputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                </div>
                <div style={styles.formGroup}>
                    <TextField
                        label="Message Title"
                        value={messageTitle}
                        onChange={(e) => setMessageTitle(e.target.value)}
                        error={!!errors.messageTitle}
                        helperText={errors.messageTitle}
                        fullWidth
                        InputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                </div>
                <div style={styles.formGroup}>
                    <TextField
                        label="Message Body"
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)}
                        error={!!errors.messageBody}
                        helperText={errors.messageBody}
                        fullWidth
                        multiline
                        rows={4}
                        InputProps={{ style: { fontSize: 16 } }}
                        InputLabelProps={{ style: { fontSize: 16 } }}
                    />
                </div>
                {loading && <CircularProgress />}
                <Button type="submit" variant="contained" color="primary" disabled={loading} sx={{ fontSize: '16px' }}>
                    {loading ? 'Sending...' : 'Send'}
                </Button>
            </form>
            <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbar.type} sx={{ width: '100%' }}>
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ContactMe;
