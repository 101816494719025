import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import BlogPost from './BlogPost';
import App from "../App";

const MyApp = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<App/>} />
                <Route path="/my-blogs/:blogId" element={<BlogPost/>} />
            </Routes>
        </Router>
    );
};

export default MyApp;
