import React, { useState } from 'react';
import blogs from "./blogs";
import Markdown from "markdown-to-jsx";
import {useNavigate} from "react-router-dom";

const BlogSection = () => {
    const [selectedBlog, setSelectedBlog] = useState(null);

    const navigate = useNavigate();

    const handleBlogClick = (blogId) => {
        navigate(`/my-blogs/${blogId}`);
    };

    const openModal = async (blogId) => {
        try {
            handleBlogClick(blogId);
            // const response = await fetch(`/blogs/${blogId}`);
            // const content = await response.text();
            // setSelectedBlog({ id: blogId, content });
        } catch (error) {
            console.error('Error fetching blog content:', error);
        }
    };

    const closeModal = () => {
        setSelectedBlog(null);
    };
    const styles = {
        container: {
            textAlign: 'center',
            margin: '40px 0', // Add margin to top and bottom
        },
        blogList: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        },
        blogItem: {
            padding: '10px',
            margin: '10px',
            border: '1px solid #ccc',
            borderRadius: '4px',
            maxWidth: '300px',
            width: '100%',
            textAlign: 'center',
            cursor: 'pointer',
        },
        blogImage: {
            width: '100%',
            height: '200px',
            objectFit: 'cover',
            borderRadius: '4px',
        },
        blogTitle: {
            marginTop: '10px',
        },
        modalOverlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        modalContent: {
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '0', // Remove border radius to make it take full screen
            width: '100%',
            height: '100%',
            position: 'fixed',
            top: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'auto', // Make it scrollable
        },
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            background: 'none',
            border: 'none',
            fontSize: '20px',
            cursor: 'pointer',
            color: '#333', // Change color to improve contrast
        },
    };

    return (
        <div style={styles.container}>
            <h2>My Blogs</h2>
            <div style={styles.blogList}>
                {blogs.map((blog) => (
                    <div
                        key={blog.id}
                        style={styles.blogItem}
                        onClick={() => openModal(blog.file)}
                    >
                        <img src={blog.image} alt={blog.title} style={styles.blogImage} />
                        <div style={styles.blogTitle}>{blog.title}</div>
                    </div>
                ))}
            </div>
            {selectedBlog && (
                <div style={styles.modalOverlay} onClick={closeModal}>
                    <div
                        className={"modal-content"}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <button style={styles.closeButton} onClick={closeModal}>
                            &times;
                        </button>
                        <h2>{blogs.find(blog => blog.id === selectedBlog.id)?.title}</h2>
                        <Markdown>{selectedBlog.content}</Markdown>
                        {/*<div dangerouslySetInnerHTML={{ __html: selectedBlog.content }} />*/}
                    </div>
                </div>
            )}
        </div>
    );
};

export default BlogSection;