import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Markdown from 'markdown-to-jsx';
import blogs from "./blogs";

const BlogPost = () => {
    const { blogId } = useParams();
    const [blogContent, setBlogContent] = useState(null);
    const [blogTitle, setBlogTitle] = useState('');

    useEffect(() => {
        const fetchBlogContent = async () => {
            try {
                const response = await fetch(`/blogs/${blogId}`);
                const content = await response.text();
                setBlogContent(content);
                const blog = blogs.find(blog => blog.id === blogId);
                setBlogTitle(blog?.title || 'Blog Post');
            } catch (error) {
                console.error('Error fetching blog content:', error);
            }
        };

        fetchBlogContent();
    }, [blogId]);

    if (!blogContent) {
        return <div>Loading...</div>;
    }

    const styles = {
        container: {
            padding: '20px',
            maxWidth: '800px',
            margin: '0 auto',
            textAlign: 'left',
        },
        title: {
            marginBottom: '20px',
        },
        markdown: {
            fontSize: '18px', // Increase the font size
            lineHeight: '1.6', // Improve readability with line height
        },
    };

    return (
        <div style={styles.container}>
            <h2 style={styles.title}>{blogTitle}</h2>
            <div style={styles.markdown}>
                <Markdown>{blogContent}</Markdown>
            </div>
        </div>
    );
};

export default BlogPost;