const blogs = [
    {
        id: 1,
        title: 'Flutter Development',
        file: 'first-blog.md',
        content: `
      # Flutter Development
      This is the content of the first blog.
    `,
        image: 'blog_images/11-Tips-to-Improve-Your-Flutter-Development-Productivity-in-Android-Studio.png',
    },
    {
        id: 2,
        title: 'Android App Development',
        file: 'second-blog.md',
        content: `
      # Android App Development 
      This is the content of the second blog.
    `,
        image: 'blog_images/android_development.png',
    },
    // Add more blogs as needed
];

export default blogs;